import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as apiList from '../../../apiList'
import { postData,formaxios } from '../../../Util'
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AddIcon from '@material-ui/icons/Add';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import TextField from '@material-ui/core/TextField';
import styles from './ByVehicle.module.css';
import classnames from 'classnames';
import moment from 'moment';
import * as alasql from 'alasql';
import XLSX from 'xlsx';
import CircularProgress from '@material-ui/core/CircularProgress';

import { GmapsDispatchThunk } from '../../../store/actions/GmapsActions';

const ByVehicle = (props) => {
  const [vinNumber, setVinNumber] = useState('')
  const [vinNumberData, setVinNumberData] = useState([])
  const [selectedVin, setSelectedVin] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [vehicleData, setVehicleData] = useState({});
  const [showpopup, setshowpopup] = useState(false);
  const [popuptext, setpopuptext] = useState('');
  const [closewindow, setclosewindow]=useState(false);

  const handleInputChange = (e) => {
    setVinNumber(e.target.value)
  }

  const handleDropdownChange = (e, v) => {
    if(v != null){
      setVinNumber(v.vin_number);
      setVehicleData(v);
    }
  }

  const handleSearchDropdownChange = (e, v) => {
    setSearchValue(v)
  }

  const handleResetButtonClick = e => {
    setVinNumber('')
    setVinNumberData([])
    setSelectedVin([])
    setSearchValue('')
  }

  const handleAddButtonClick = (e) => {
    const newSelectedVin = [...selectedVin]
    if(newSelectedVin.indexOf(vinNumber) === -1 && (vinNumberData.filter(obj => obj.vin_number === vinNumber).length !== 0)) {
      newSelectedVin.push(vinNumber)
      setSelectedVin(newSelectedVin)
      // setVinNumber('')
      let el = document.getElementsByClassName('MuiIconButton-label')[0];
      el.click();
    }
  }

  const handleRemoveButtonClick = e => {
    const newSelectedVin = [...selectedVin]
    const newArray = newSelectedVin.filter(val => val !== searchValue)
    setSelectedVin(newArray)
    setSearchValue('')
  }

  const handleListItemClick = ele => {
    setSearchValue(ele)
  }

  const submitByVehicle = () => {
    var inputValue = document.getElementsByClassName('MuiTypography-root MuiListItemText-primary MuiTypography-body2')[0];
    if(inputValue){
      if(selectedVin.length < 200){
        let dataObj = {
          platform: "w",
          userId: props.ctr.userid,
          loginId: '',
          mappingType:"vehicle",
          vin: selectedVin
        };

        let vehtoken = props.ctr.token;
        const datas = postData(apiList.createVIN, dataObj, vehtoken);  //http://172.16.11.161:8000/api/aluserlogin/createVIN
        datas.then((dataVals) => {
            if(dataVals.statuscode === 200){
              props.callBack();
              props.gmapsDispatch({ showWelcomeModal: false })
              props.updateSubcription();
              let userDet = {};
              userDet.userInfo = props.ctr;
              userDet.userInfo.globalFilter = true;
              props.userData({ ...userDet.userInfo });
            }
        });
      }else{
        alert('You have exceeded the maximum selection. Please select less than 200 vehicles.')
      }
    }
  }

  useEffect(() => {
    if (vinNumber && vinNumber.length >= 4) {
      const vinDetails = {
        platform: props.ctr.platform,
        vinNumber: vinNumber.toUpperCase()
      }
      const token = props.ctr.token
      async function fetchData() {
        const data = await postData(apiList.searchByVin, vinDetails, token);  //http://172.16.11.161:8000/api/aluserlogin/getvin
        const vinData = data.data
        setVinNumberData(vinData)
      }
      fetchData()
    }
  }, [vinNumber, props]);

  var defaultProps = {
    options: vinNumberData,
    getOptionLabel: option => {
      // option.vin_number && option.regn_number
      if(option && option.vin_number){
        return `${option.vin_number} - ${option.regn_number}`;
      }
    }
  };

  const bulkupload = () => {
    document.getElementById('bulk').click();
  };

  const fileUpload = (e) => {
    let loader = document.getElementsByClassName('mv_loader')[0];
    let tokens = props.ctr.token;
    var bodyFormData = new FormData();
    let excel = e.target.files[0];
    bodyFormData.append('alUserBulkUpload', excel);
    let bulkurl = apiList.alUserBulkUpload;
    if (excel.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      document.getElementById("bulk").value = "";
      setpopuptext("Invalid Template! Please use correct template.");
      setshowpopup(true);
    }
    else {
      loader.style.display = 'block';
      let datas = formaxios(bulkurl, bodyFormData, tokens);
      datas.then((excelexport) => {
        if (excelexport.data.statuscode === 204) {
          document.getElementById("bulk").value = "";
          setpopuptext("Failure! Please fill the mandatory details.");
          setshowpopup(true);
          loader.style.display = 'none';
        }else {
          let success = 0;
          let fail = 0;
          let str = "Vehicle has been Mapped successfully";
          let strchk = str.toUpperCase()

          for (let i = 0; i < excelexport.data.length; i++) {
            if (excelexport.data[i]['response'].trim().toUpperCase() === strchk) {
              success = success + 1;
            }
            else {
              fail = fail + 1;
            }
          }

          if (fail === 0 && success > 0) {
            setpopuptext("Success! Vehicle(s) has been Mapped Successfully");
            setshowpopup(true);
            setclosewindow(true)
          }
          else {
            setpopuptext("Failure! Please check the remarks in output file.");
            setshowpopup(true);
            setclosewindow(true)
          }

          alasql.fn.moment = moment;
          alasql(`SELECT 
                vinNumber as [VIN Number],
                response as [Remarks]
                INTO XLSX("AL_VehicleBulkUploadRemarks", {headers:true}) FROM ?`, [excelexport.data]);
          document.getElementById("bulk").value = "";

          if (success !== 0) {
            props.callBack();
          }
          loader.style.display = 'none';
          
        }
      });
    }
  };

  const closePop = () => {
    if (closewindow===true){
      setpopuptext("")
      setshowpopup(false)
      setclosewindow(false)
      props.gmapsDispatch({ showWelcomeModal: false })
    }
    else{
    setpopuptext("")
    setshowpopup(false)
    }
  }
  
  return (
    <div className={styles.ByVehicleContainer}>
      <div className={styles.VinNumberAdd}>
      <div className={classnames("modal", showpopup ? "active-modal" : null)}>
          <div className="modal-content" style={{ marginTop: '10%' }}>
            <div className="row">
              <span className="col-lg-11 col-sm-11">{popuptext}</span>
              <span className="col-lg-1 col-sm-1 close" onClick={closePop}>&times;</span>
            </div>
          </div>
        </div>
        <CircularProgress className="mv_loader" style={{ left: '50%', position: 'absolute', display: 'none' }} />
        <Autocomplete
          id="free-solo-demo"
          freeSolo
          value={vehicleData ? vehicleData : {}}
          {...defaultProps}
          onChange={handleDropdownChange}
          renderInput={params => (
            <TextField
              {...params}
              id="standard-basic"
              label="Vin Number"
              style={{width: '50%'}}
              type="search"
              onChange={handleInputChange}
              value={vinNumber}
            />
          )}
        />
        <div>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={handleAddButtonClick}
          >
            Add
          </Button>
        </div>
      </div>

      <div className={styles.SearchVehicle}>
        <Autocomplete
          id="free-solo-demo"
          freeSolo
          value={searchValue}
          disabled={selectedVin.length === 0}
          options={selectedVin.map(vin => vin)}
          onChange={handleSearchDropdownChange}
          renderInput={params => (
            <TextField
              {...params}
              id="standard-basic"
              label="Search"
              style={{width: '50%'}}
              type="search"
              value={searchValue}
              disabled={selectedVin.length === 0}
              // onChange={handleSearchValueChange}
            />
          )}
        />
        
        <div>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<DeleteForeverIcon />}
            onClick={handleRemoveButtonClick}
          >
            Remove
          </Button>
        </div>
      </div>

      <div className={styles.containerbulkupload}>
        <div>
        <div className={styles.ListContainer}>
          <List dense>
            {selectedVin.map((ele, index) => (
              <ListItem 
                key={ele + index}
                onClick={() => handleListItemClick(ele)}
              >
                <ListItemText
                  primary={ele}
                />
              </ListItem>
            )
            )}
          </List>
            
          </div>
          </div>
          <div>
            <Button
              style={{marginTop:'4%', marginRight:'10%'}}
              variant="contained"
              color="secondary"
              startIcon={<i className="fa fa-upload" aria-hidden="true"></i>}
              onClick={bulkupload}
            >
              <input type="file" id="bulk" onChange={fileUpload} name="file" accept=".xlsx, .xls" hidden />
              Bulk Upload
            </Button>
            <a href="/images/bulkupload/AL_UserBulkUpload.xlsx" download>
            <Button
              style={{marginTop:'4%'}}
              variant="contained"
              color="secondary"
              startIcon={<i className="fa fa-download" aria-hidden="true"></i>}
            >
              
              Template
            </Button>
            </a>
          </div>
        </div>

      <div className={styles.ResetSubmit}>
        <div className={styles.ResetSubmit}>
          <Button
            variant="contained"
            color="secondary"
            endIcon={<ArrowForwardIosIcon />}
            onClick={submitByVehicle}
          >
            Submit
          </Button>
        </div>
        <div className={styles.ResetSubmit}>
          <Button
            variant="contained"
            color="secondary"
            endIcon={<RotateLeftIcon />}
            onClick={handleResetButtonClick}
          >
            Reset
          </Button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  ctr: state.userdata.id
})

const mapdispatchToprops = dispatch => {
  return {
      gmapsDispatch: (payload) => dispatch(GmapsDispatchThunk(payload)),
      userData: (data) => dispatch({ type: 'INCREMENT', value: data }),
  };
}

export default connect(mapStateToProps, mapdispatchToprops)(ByVehicle)

