import React, { useState, useEffect } from 'react';
import axios from "axios";
import moment from 'moment';
import JSZip from 'jszip';
import { setHours, setMinutes } from 'date-fns';
export const phoneno = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;    //validation for mobile Number
export const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;        //validation for mail ID
export const alphabets = /^[A-Za-z]+$/;         //validation for alphabets
export const alphabetswithspace = /^[A-Z a-z]+$/;         //validation for alphabets with space
export const numbers = /^[0-9]+$/;              // Numbers
export const alphaNumeric = /^[0-9a-zA-Z]+$/;   // Alphabets with Numeric chars
export const splchar = /^[!@#$%^&*]+$/;         // Validate Special characters
export const passwdVal = /^[A-Za-z0-9!@#$%^&*]+$/; // Validate Password characters
export const alphaNumericSpace = /^[0-9a-zA-Z -]*$/;
export const alphaNumericSpace_poi = /^[0-9a-zA-Z ]*$/;  // AlphaNumeric chars with space
export const allowparticualrchar = /^[^']+$/;
//export const apiurl= "http://10.60.64.204";
export const apiurl = "http://10.60.64.212/api";
export const spaceFormat = (input) => {
    return input.replace(/^\s+|\s{2,}/g, ' ');
}; //not allow initial spaces 





function PopupFunction (props) {
    let msg = props.msg;
    var [clicked, setClickVal] = useState(false);
    console.log(props.status);

    function clickVals (vals) {
        setClickVal(vals);
    }

    useEffect(() => {
        setClickVal(false)
    }, []);



    return (
        <div className="" style={clicked === false ? { display: "block" } : { display: "none" }}>
            <div style={{ position: "fixed", width: "100%", height: "100%", top: 0, left: 0, backgroundColor: "rgba(0, 0, 0, 0.5)", overflow: "hidden", zIndex: 99999 }}>
                <div style={{ background: "#FFF", width: "60%", padding: "40px", top: "15%", position: "absolute", left: "20%", fontFamily: "calibri" }}>
                    <p className="text-right"><span style={{ cursor: "pointer" }} onClick={() => clickVals(true)}>&#x2716;</span></p>
                    <p>{msg}</p>
                </div>
            </div>
        </div>
    )

}

export const postData = async (url, datas, tokens) => {
    try {
        const response = await fetch(url, {
            'method': 'POST',
            'Accept': '*',
            'Access-Control-Allow-Origin': '*',
            "headers": {
                'Content-Type': 'application/json',
                "Authorizationl": tokens
            },
            'body': JSON.stringify(datas)
        });
        if (!response.ok) {
            console.log("Network response was not ok");
        }
        return await response.json();
    } catch (error) {
        console.log('An error occurred while making the request: ' + error.message);
    }
}

export const filePostData = async (url, file, tokens) => {
    const response = await fetch(url, {
        'method': 'POST',
        'Accept': '*',
        'Access-Control-Allow-Origin': '*',
        "headers": {
            "Authorizationl": tokens
        },
        'body': file
    })
    return await response.json();
}

export const fileUploadAndDownload = async (url, file, tokens) => {
    const response = await fetch(url, {
        method: "POST",
        Accept: "*",
        "Access-Control-Allow-Origin": "*",
        headers: {
            Authorizationl: tokens,
        },
        body: file,
    });
    return response;
};

export const formDataMethod = async (url, formdata, tokens) => {
    const response = await fetch(url, {
        'method': 'POST',
        'Accept': '*',
        'Access-Control-Allow-Origin': '*',
        "headers": {
            "Authorizationl": tokens
        },
        'body': formdata
    })
    return await response.json();
}

export const postStreamData = async (url, datas, tokens) => {
    const response = await fetch(url, {
        'method': 'POST',
        'Accept': '*',
        'Access-Control-Allow-Origin': '*',
        "headers": {
            'Content-Type': 'application/json',
            "Authorizationl": tokens
        },
        'body': JSON.stringify(datas)
    });
    return await response;
}


export const getDatatab = async (url) => {
    var request = new XMLHttpRequest()
    // const response = await fetch(url.mainurl+"?server="+url.partid+"&username="+url.userid+"&target_site=iALERT", {
    //     'method' : 'GET',
    //     'Content-Type' : 'application/json',
    //     'Accept' : 'application/json',
    //     'Access-Control-Allow-Origin': '*',
    //     'crossDomain':true
    // }).then(function(data) {
    //    console.log(data.text())
    //     })
    // return await response
    request.open('GET', url.mainurl + "?server=" + url.partid + "&username=" + url.userid + "&target_site=iALERT", true)

    request.onload = function (data) {
        console.log(data.target.response)

    }

    // Send request
    request.send()
    return
}

export const postDatalogin = async (url, datas, captchadetails) => {
    //let contentType = { 'Content-Type' : 'application/json' };
    //let headersVal = {...contentType, ...captchadetails};
    //console.log(headersVal);
    const response = await fetch(url, {
        'method': 'POST',
        'Accept': '*',
        'Access-Control-Allow-Origin': '*',
        "headers": {
            'Content-Type': 'application/json',
            'entry': captchadetails.entry,
            'entryid': captchadetails.entryid,
            'userDomain': captchadetails.userDomain,
            'browserDetails': captchadetails.browserDetails,
            'osName': captchadetails.osName,
            'bandwidth': captchadetails.bandwidth,
            'clientIp': '',
            'macAddress': '',
            'computerName': ''
        },

        'body': JSON.stringify(datas)
    })
    return await response.json();
}

export const postDataTracking = async (url, datas, captchadetails) => {
    const response = await fetch(url, {
        'method': 'POST',
        'Accept': '*',
        'Access-Control-Allow-Origin': '*',
        "headers": {
            'Content-Type': 'application/json',
            'userDomain': captchadetails.userDomain,
            'browserDetails': captchadetails.browserDetails,
            'osName': captchadetails.osName,
            'bandwidth': captchadetails.bandwidth,
            // 'clientIp': '',
            // 'macAddress': '',
            // 'computerName': ''
        },
        'body': JSON.stringify(datas)
    })
    return await response.json();
}


export const getData = async (url, datas) => {
    let urls;
    if (datas === undefined) {
        urls = url
    } else {
        urls = url + '/' + datas;
    }


    const response = await fetch(urls, {
        'method': 'GET',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
    })
    return await response.json();
}

export const GETDATA = async (url, tokens) => {
    const response = await fetch(url, {
        'method': 'GET',
        'Accept': '*',
        'Access-Control-Allow-Origin': '*',
        "headers": {
            'Content-Type': 'application/json',
            "Authorizationl": tokens
        },
        'body': JSON.stringify()
    })
    return await response.json();
}

export const restrictNumberToSixDigits = (number) => {
    const numberString = String(number);
    if (numberString.length > 6) {
      return parseInt(numberString.slice(0, 6));
    }
    return parseInt(numberString);
  }

export const allowNumbers = (event) => {
    if (event.charCode <= 47 || event.charCode >= 58) {
        event.preventDefault();
        return false;
    }
}

export const allowWithoutSpace = (event) => {
    if (event.charCode === 32) {
        event.preventDefault();
        return false;
    }
};


export const allowNumberswithDot = (event) => {
    if (event.charCode <= 45 || event.charCode >= 58) {
        event.preventDefault();
        return false;
    }
}

export const allowalphabets = (event) => {
    if (!((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122))) {
        event.preventDefault();
        return false;
    }
}

export const setLocalStorage = (key, value) => {
    return window.localStorage[key] = value;
}

export const getLocalStorage = (key) => {
    return window.localStorage[key];
}

export const removeLocalStorage = (key) => {
    return window.localStorage.removeItem(key);
}

//dateformat dd/mm/yyyy to yyyy-mm-dd
export const changeformatDate = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dateinMonth = date.getDate();

    let formatMonth = month < 10 ? '0' + month : month;
    let formatDate = dateinMonth < 10 ? '0' + dateinMonth : dateinMonth;

    let dateString = year + "-" + formatMonth + "-" + formatDate;
    return dateString;
}

//dateformat dd/mm/yyyy to yyyy-mm-dd
export const changeformatDate2 = (date) => {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dateinMonth = date.getDate();

    let formatMonth = month < 10 ? '0' + month : month;
    let formatDate = dateinMonth < 10 ? '0' + dateinMonth : dateinMonth;

    let dateString = formatDate + "_" + formatMonth + "_" + year;
    return dateString;
}

//dateformat yyyy-mm-dd to dd/mm/yyyy
export const changeNewformatDate = (dateStr) => {
    var date = new Date(dateStr);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dateinMonth = date.getDate();

    let formatMonth = month < 10 ? '0' + month : month;
    let formatDate = dateinMonth < 10 ? '0' + dateinMonth : dateinMonth;

    let dateString = formatMonth + "/" + formatDate + "/" + year;
    return dateString;
}

//dateformat yyyy-mm-dd to dd-mm-yyyy
export const changeNewformatDate2 = (dateStr) => {
    var date = new Date(dateStr);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dateinMonth = date.getDate();

    let formatMonth = month < 10 ? '0' + month : month;
    let formatDate = dateinMonth < 10 ? '0' + dateinMonth : dateinMonth;

    let dateString = formatDate + "-" + formatMonth + "-" + year;
    return dateString;
}

// format date in date picker yyyy-mm-dd to dd-mm-yyyy
export const ChangeDatepickerFormatDate = (dateString) => {
    if (!dateString) return null;
    const [year, month, day] = dateString.split('-');
    if (!year || !month || !day) return null;
    return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
};

export const isDate = (d) => {
    return d instanceof Date && !isNaN(d);
}

export const changeTimeFormat = (date) => {
    let hour = date.getHours();
    let minute = date.getMinutes();

    let formatminute = minute < 10 ? '0' + minute : minute;
    let formathour = hour < 10 ? '0' + hour : hour;

    let timeString = formathour + ":" + formatminute + ":00";
    return timeString;
}

export const HoursMinites = (date) => {
    let hour = date.getHours();
    let minute = date.getMinutes();

    let formatminute = minute < 10 ? '0' + minute : minute;
    let formathour = hour < 10 ? '0' + hour : hour;

    let timeString = formathour + ":" + formatminute;
    return timeString;
}

export const formaxios = async (url, datas, tokens) => {
    const response = axios({
        method: 'POST',
        url: url,
        data: datas,
        headers: {
            'Content-Type': 'multipart/form-data',
            "Authorizationl": tokens
        }
    })
    return response;
}
export const formaxiosJson = async (url, datas, tokens) => {
    const response = axios({
        method: 'POST',
        url: url,
        data: datas,
        headers: {
            'Content-Type': 'application/json',
            "Authorizationl": tokens
        }
    })
    return response;
}

export const mathRound = (num) => {
    return Math.round(num * 100) / 100
}

//2020-05-13T08:36:13
export const changeTimestampFormat = (timeStamp) => {
    return timeStamp !== null && timeStamp !== undefined &&
        timeStamp.substring(8, 10) + "-" + timeStamp.substring(5, 7) + "-" + timeStamp.substring(0, 4) + " " + timeStamp.substring(11, 19) || "";
}

export const changeTimestampFormatpm = (timeStamp) => {
    return timeStamp !== null && timeStamp !== undefined &&
        timeStamp.substring(8, 10) + "-" + timeStamp.substring(5, 7) + "-" + timeStamp.substring(0, 4) + " " + timeStamp.substring(11, 19) + " " + timeStamp.substring(20, 22) || "";
}

export const changeDateFormat = (date) => {
    return date !== null && date !== undefined &&
        date.substring(6, 10) + "-" + date.substring(3, 5) + "-" + date.substring(0, 2) || "";
}

export const exceldateformatter = (date) => {
    // var date_info = new Date(date)
    // return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate());
    return (moment(date).format('DD-MM-YYYY'));
}

export const exceldatetimestampformatter = (date) => {
    // return new Date(date)
    // var date_info = new Date(date)
    // return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(),date_info.getHours(),date_info.getMinutes(),date_info.getSeconds());
    return (moment(date).format('DD-MM-YYYY hh:mm:ss A'));
}

export const changeformatDateString = (dateString) => {
    const parts = dateString.split('-');
    if (parts.length === 3) {
        return `${parts[2]}-${parts[1]}-${parts[0]}`; // Format as 'yyyy-mm-dd'
    }
    return dateString; // If the date string is not in the expected format, return as is
}

export { PopupFunction };

export const isExpired = (linkExpireTime) => {
    var expireTime = new Date(linkExpireTime);
    var expireTimeMilliSecond = expireTime.getTime() / 1000;
    if (expireTimeMilliSecond < new Date().getTime() / 1000) {
        return true;
    } else {
        return false;
    }
};
export const checkClientServerTime = (serverTimeString) => {
    var serverTime = new Date(serverTimeString);
    var expireTimeMilliSecond = serverTime.getTime() / 1000;
    if (expireTimeMilliSecond < new Date().getTime() / 1000) {
        return true;
    } else {
        return false;
    }
};

export const formatLocalTimeStamp = (reportingTime) => {
    if (reportingTime) {
        reportingTime = reportingTime.replaceAll("-", "/")
        return moment(reportingTime).format(
            "MMM DD, YYYY hh:mm:ss A"
        );
    }
}

export const getBlobDownload = (blob, filename) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export const DownloadBlobFileToPdf = (blob, filename) => {
    const link = document.createElement("a");
    const file = new Blob([blob], { type: 'application/pdf' })
    link.href = URL.createObjectURL(file);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
};

export const UnzipBlobFileToPdf = async (blob) => {
    const zip = new JSZip();
    const zipData = await zip.loadAsync(blob);
    const downloadContainer = document.createElement("div");
    zipData.forEach(async (relativePath, file) => {
        const fileBlob = await file.async("blob");
        const fileBlobUrl = URL.createObjectURL(fileBlob);
        const fileLink = document.createElement("a");
        fileLink.href = fileBlobUrl;
        fileLink.download = file.name;
        fileLink.textContent = `Download ${file.name}`;
        downloadContainer.appendChild(fileLink);
        fileLink.click();
    });

}

export const TimestampToCustomFormat = (timestamp) => {
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
}

//dateformate dd/mm/yyyy/hh/mm/ss to dd/mm/yyyy/hh/mm
export const changeDateTimeFormateAMPM = (date) => {
    const getdate = new Date(date)
    const options = {
        timeZone: 'Asia/Kolkata',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    };

    const formattedDate = getdate.toLocaleString('en-IN', options).replaceAll('/', '-')
    return formattedDate;
}

export const datawithtime = (date) => {
    return (moment(date).format('DD-MM-YYYY hh:mm:ss'));
}

export const excelFileNameDateTime = (File_name, sDate, eDate) => {
    return (File_name + ' ' + sDate + " to " + eDate)
}

export const calculateDifferenceInDays = (startDate, endDate) => {
    return Math.round(Math.abs((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1); // Calculate difference in days
}

export const DateConversion = (value) => {
    return moment(value).format("YYYY-MM-DD")
}

// const moment = require('moment-timezone');
export const TimeConversion = (timeString) => {
    const indiaTime = moment(timeString, "YYYY-MM-DDTHH:mm:ssZ");
    const desiredTime = indiaTime.clone();
    return desiredTime.format("HH:mm");
}

export const RistrictEmptySpace = (e) => {
    if (e.key === ' ') {
        e.preventDefault();
    }
}
export const addDays = (date, days) => {
    let dateVal = date;
    dateVal.setDate(date.getDate() + days)
    return dateVal;
}

export const minTime = setHours(setMinutes(addDays(new Date(), 0), 0), 9);
export const maxTime = setHours(setMinutes(addDays(new Date(), 0), 0), 19);


export const mailRegex = (event) => {
    const regex = /^[a-zA-Z0-9@.]+$/;
    if (event.key !== 'Backspace' && (event.key === ' ' || !regex.test(event.key))) {
        event.preventDefault();
    }
};

export const restrictSpecialCharactersAndSpaces = (event) => {
    const regex = /^[0-9\b]+$/;
    if (event.key !== 'Backspace' && (event.key === ' ' || !regex.test(event.key))) {
        event.preventDefault();
    }
}
export const restrictSpecialCharactersAndNumbers = (event) => {
    const alphabets = /^[A-Za-z\s]+$/;
    if (event.key !== 'Backspace' && (!alphabets.test(event.key))) {
        event.preventDefault();
    }
}
export const allTypingRistrict = (event) => {
    const allowedKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Backspace', 'Delete', 'Tab'];
        // Allow only specific keys
        if (!allowedKeys.includes(event.key)) {
            event.preventDefault();
        }
}

export const hasConsecutiveDigits = (input, consecutiveCount) => {
    for (let i = 0; i <= input.length - consecutiveCount; i++) {
        const consecutiveDigits = input.slice(i, i + consecutiveCount);
        if (consecutiveDigits.split('').every((digit, index) => digit === consecutiveDigits[0])) {
            return true;
        }
    }
    return false;
}

export const restrictSpecialCharacters = (e) => {
    const specialCharactersRegex = /[^a-zA-Z0-9., ]/g;
    if (specialCharactersRegex.test(e.key)) {
        e.preventDefault();
    }
};

